import styled from "styled-components"

export const Main = styled.div`
  margin-bottom: var(--gapM);
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 100%;
  grid-column: 1/-1;

  a {
    text-decoration: underline;
  }

  b {
    font-weight: bold;
  }

  h1 {
    font-size: 1.65em;
    margin-bottom: 35px;
  }
  h2 {
    font-size: 1.45em;
    margin-bottom: 5px;
  }

  @media (max-width: 992px) {
    margin-top: var(--gapL);
  }
`

export const Container = styled.div`
  section {
    margin-bottom: 50px;
    flex-direction: column;
  }

  @media (max-width: 768px) {
    padding: 0 var(--gapXS);
  }

  & * {
    color: #595959;
  }
  h1 {
    color: unset;
  }
  h2 {
    margin-top: var(--gapS);
  }
  h2 {
    color: grey;
    & > * {
      color: black;
    }
    @media (max-width: 768px) {
      font-size: 16px;
    }
  }
  p {
    margin: var(--gapS) 0;
    white-space: pre-wrap;
    white-space: -moz-pre-wrap;
    white-space: -pre-wrap;
    white-space: -o-pre-wrap;
    word-wrap: break-word;
  }
  ul {
    margin-left: var(--gapS);

    h2 {
      margin-top: var(--gapXS);
    }
    li {
      margin-bottom: var(--gapS);
    }
  }
  a {
    white-space: pre-wrap;
    white-space: -moz-pre-wrap;
    white-space: -pre-wrap;
    white-space: -o-pre-wrap;
    word-wrap: break-word;
    color: var(--clr-gold);

    &:hover {
      text-decoration: underline;
    }
  }
`
