import React from "react"
import Datenschutz from "../modules/datenschutz"
import Layout from "../modules/layout"
import Seo from "../modules/seo"

const datenschutz = ({ location }: any) => {
  return (
    <Layout location={location}>
      <Seo title="Datenschutz">
        <Datenschutz />
      </Seo>
    </Layout>
  )
}

export default datenschutz
